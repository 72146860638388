<template lang="">
  <v-row id="header" class="elevation-8 py-0"   align-self="center" >
    <v-col cols="9" md="8" sm="7" class="bandeau_blanc py-3" align-self="center" align="left">
      <router-link :to="{ name: 'home'}" >
        <img src="@/assets/logoFta.png" alt="" />
      </router-link>
      <a class="ml-3" target="blank" href="https://commande.f-t-a.com">Commandes</a>
      <a class="ml-3" target="blank" href="https://www.planningpme.fr/mon-compte/sign">Planning</a>
    </v-col>
    <v-col cols="3"  xl="3" lg="3" md="4" sm="4" offset-lg="1" class="identifiant_connect_logout" align-self="center" align="right">
      <v-row  align="center">
        <v-col cols="3" class="id_connect bold" align-self="center">
          <img class="logo_avatar" src="@/assets/avatar.png" />
          </v-col>
          <v-col cols="7" align="left" class="user">
          {{ this.$store.state.user.nom ? this.$store.state.user.nom.toUpperCase(): "" }}
        </v-col>
        <v-col cols="2" class="id_connect" align-self="center" align="right">
          <v-btn text @click="logout()">
            <img src="@/assets/logoExit.png" />
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem("user-token");
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
#header {
  .bandeau_blanc {
    text-align: left;
    img {
      max-width: 10%;
    }
  }
  .identifiant_connect_logout {
    text-align: right;
  }
}
div#header {
  padding: 10px;
}
.logo {
  width: 10%;
  margin: 1.2%;
}
.logo_avatar {
  max-height: 78%;
  padding-right: 1%;
}

.id_connect {
  color: #2887b9;
}
.fa-user-circle {
  color: #2887b9;
}
.fa-sign-out-alt {
  color: #c8312d;
}
.bold {
  font-weight: bold;
}
.user {
  font-weight: bold;
  font-size: 1.5rem;
  color: #2f7bb4;
}
@media screen and (max-width: 1366px) {
  .user {
    font-size: 1.2rem;
  }
   #header .bandeau_blanc img {
    max-width: 19%;
  }

}
@media screen and (max-width: 1280px) {
  .user {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1024px) {
 
.font_size{
  font-size: 0.86vw;
}
}
</style>
